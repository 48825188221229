import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  Notification,
  Typography,
  MuiIcon,
  Form,
  Icon,
  Divider,
  Hidden,
} from "components"
import { ClaimCard } from "components/advance/ClaimCard"
import { UploadDocumentCard } from "components/advance/UploadDocumentCard"
import { EnumClaimRequestStatus } from "constants/enums/claim-request-status"
import dayjs from "dayjs"
import { compose, withConfirmGoBack, withConfirmRefresh, withFormik, withHooks, withStores } from "enhancers"
import { Field } from "pages/main/claim-detail/form-field"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import { AppColor } from "theme/app-color"
import { employeeIsHr, gql, isJson, paths, stringToDate, toCurrency } from "utils/helper"
import { InfoValuesConfig } from "pages/main/claim-detail"
import { INPUT_TYPE } from "constants/enums/input-type"
import { OptionValue } from "constants/enums/option-value"
import { PageContent } from "layouts"
import ClaimConditions from "components/advance/ClaimConditions"
import { RequiredDocumentCard } from "components/advance/RequiredDocumentCard"
import ClaimRemarks from "components/advance/ClaimRemarks"
import { env } from "configs"
import { IconName } from "components/common/Icon"
import { get, isEmpty } from "lodash"
import { RejectReasonComponent } from "./RejectReasonComponent"
import { ReactComponent as Point } from "assets/icon/point.svg"
import { Card, CardContent } from "@material-ui/core"
import SuccessPage from "pages/main/claim-detail/PageSuccess"
import BudgetComponent from "../BudgetComponent"
import LoadingModal from "components/LoadingModal"
import ConsentModal from "pages/main/claim-detail/consent-modal"
import { EnumClaimAction } from "constants/enums/claim-action"

const CustomIconList = styled(MuiIcon)`
  font-size: 20px;
  margin-left: 8px;
`
const WarningIcon = styled(MuiIcon)`
  font-size: 16px;
  margin-right: 4px;
`

const WarningBox = styled(Box)<{ mt?: string; mb?: string }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 8px;
  height: 32px;
  background-color: ${AppColor["System/Warning Light"]};
  border-radius: 8px;
  margin-top: ${(props) => (props.mt ? props.mt : "0px")};
  margin-bottom: ${(props) => (props.mb ? props.mb : "0px")};
`

const List = styled.ul`
  margin: 0px 0px 0px -16px;
`

const ContentBox = styled(Box)`
  padding: 16px;
`
const Header = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  margin-bottom: 7px;
  .closeBtn {
    border: none;
    position: absolute;
    right: 0px;
  }
`
const Main = styled("div")`
  overflow-y: scroll;
  color: ${AppColor["Text/Secondary"]};
  height: 500px;
  width: 100%;
`

const EditPageComponent = (props: any) => (
  <>
    <LoadingModal isOpen={!props.isLoaded || props.imageLoading} />
    <LoadingModal isOpen={props.validateClaimLoading} title="กำลังตรวจสอบเงื่อนไขการเบิก" />
    <LoadingModal isOpen={props.editClaimLoading} title="กำลังส่งคำขอการเบิก" />
    <ConsentModal
      isOpen={props.isConsentModalOpen}
      onClose={props.handleCloseConsentModal}
      onConfirm={props.handleClickSendRequest}
      checkHaveConsent={props.checkHaveConsent}
    />
    {props.step !== 3 && props.isLoaded && (
      <PageContent
        title={props.title}
        titleCentered
        onBack={props.handleClickBack}
        extendedTitleHeight="100px"
        overlapHeight="70px"
        type="primary"
      >
        <ContentBox>
          <ClaimCard
            title={props.title}
            type={props.type}
            name={props.name}
            approvedRequest={props.approvedCount}
            totalAmount={props.approvedAmount}
            icon={props.icon}
            iconColor={props.iconColor}
            chipTitleColor={props.chipTitleColor}
            chipBackgroundColor={props.chipBackgroundColor}
            fullSize
            displayOnly
          />
          {!props.isPreview && (
            <>
              <ClaimConditions conditions={props.conditions} />
              <RequiredDocumentCard documents={props.documents} />
              <Hidden when={props.remark?.length === 0}>
                <Divider style={{ margin: "32px 0px 4px 0px", color: AppColor["Text/Primary Text"] }} />
              </Hidden>
              <ClaimRemarks remarks={props.remark} step={1} />
              <Divider style={{ margin: "32px 0px 32px 0px", background: AppColor["Primary/Line"] }} />
              {props.parentId && (
                <RejectReasonComponent
                  rejectedBy={props.rejectedBy}
                  rejectReason={props.rejectReason}
                  remark={props.rejectRemark}
                />
              )}
            </>
          )}
          <Box
            mt={getMarginTopPreview(props.isPreview, props.hrInstead, props.currentUserIsHr, props.remark)}
            display="flex"
            alignItems="center"
          >
            {env.ICON_CONFIG.DETAIL_LABEL && (
              <Icon
                style={{ marginRight: "8px" }}
                name={env.ICON_CONFIG.DETAIL_LABEL as IconName}
                color={AppColor["Primary/Primary Text"]}
                width={24}
                height={24}
              />
            )}

            <Typography variant="h3" color="Text/Black2">
              {props.isPreview ? "ตรวจสอบรายละเอียด" : "กรอกรายละเอียดการเบิก"}
            </Typography>
          </Box>
          {props.isHr && (
            <WarningBox mt="16px" mb="16px">
              <WarningIcon name="Warning" color={AppColor["Error/Error Text"]}></WarningIcon>
              <Typography variant="body2" color={AppColor["Text/Primary Text"]}>
                ทำรายการแทนพนักงานคนอื่น
              </Typography>
            </WarningBox>
          )}
          <Form>
            <Grid container spacing={0} style={{ paddingTop: 8, marginBottom: "40px" }}>
              <Grid item xs={12} key="select-employee">
                <Box mt={props.isPreview ? "0px" : "8px"}>
                  {checkIsSupport(props.currentUserIsHr, props.title) ? (
                    <Field
                      fast={false}
                      isPreview={props.isPreview}
                      component="SELECT"
                      label="ผู้เสียชีวิต"
                      placeholder="ผู้เสียชีวิต"
                      name="requesterId"
                      value={props.requesterValues}
                      options={props.employeeOptions}
                      disabled={true}
                      disableClearable
                      required
                    />
                  ) : (
                    <Field
                      fast={false}
                      isPreview={props.isPreview}
                      component="SELECT"
                      label="ผู้ขอเบิก"
                      name="requesterId"
                      value={props.requesterValues}
                      options={props.employeeOptions}
                      disabled={true}
                      disableClearable
                      required
                    />
                  )}
                </Box>
              </Grid>
              {props.inputs?.map((input: any) => (
                <>
                  {isPreviewOrReadOnly(props.isPreview, input) && input.type === INPUT_TYPE.LABEL ? (
                    <></>
                  ) : (
                    <Grid
                      item
                      xs={12}
                      key={`${input.name}-8`}
                      style={{ paddingTop: input.type === INPUT_TYPE.SUM_AMOUNT ? 0 : 16 }}
                    >
                      <Box mt={isPreviewOrReadOnly(props.isPreview, input) ? "0px" : "8px"}>
                        <Field
                          fast={!(input.type === INPUT_TYPE.RELATION)}
                          isPreview={isPreviewOrReadOnly(props.isPreview, input)}
                          component={input.type}
                          label={input.title}
                          name={input.name}
                          disabled={input.disabled}
                          options={input.options}
                          unit={input.unit}
                          placeholder={input.placeholder}
                          required={input.required}
                          icon={input.icon}
                          iconColor={input.iconColor}
                          helperText={input.remark}
                          master={props.master}
                          requester={props.requester}
                          auto={getAuto(props.isPreview, input)}
                          requestName={props.name}
                          values={props.values}
                          setFieldValue={props.setFieldValue}
                          value={props.inputValues[input.name]}
                          defaultValue={input.value}
                          maxDate={input.maxDate}
                          minDate={input.minDate}
                          filterOptions={input.filterOptions}
                          backgroundColor={input.backgroundColor}
                          textColor={input.textColor}
                          setAdditionalList={props.setDiseaseList}
                          additionalList={props.newDiseases}
                        />
                      </Box>
                    </Grid>
                  )}
                </>
              ))}
              <BudgetComponent
                budgetDetail={props.budgetDetail}
                budgetValues={props.budgetValues}
                year={props.year}
                yearlyBudget={props.yearlyBudget}
                type={props.name}
                values={props.values}
              />
            </Grid>
          </Form>

          {props.documents.length > 0 && (
            <UploadDocumentCard
              isPreview={props.isPreview}
              documents={props.documents}
              values={props.documentValues}
              onChange={props.handleFilesChange}
              onUploading={props.handleUploading}
              onUploaded={props.handleUploaded}
              isUploading={props.isUploading}
              mb="24px"
              onLoading={(loading) => props.setImageLoading(loading)}
            />
          )}

          {props.isPreview ? (
            <>
              {env.USER_CAN_SEE_REMAINING_BUDGET && (
                <div style={{ marginBottom: "40px" }}>
                  <div style={{ display: "flex", alignItems: "center", gap: "8px", marginBottom: "16px" }}>
                    <Point></Point>
                    <Typography variant="h3" color="black">
                      สรุปการใช้คะแนน
                    </Typography>
                  </div>
                  <Card style={{ boxShadow: " 0 0 1px" }}>
                    <CardContent style={{ padding: "16px" }}>
                      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "8px" }}>
                        <Typography variant="h4" color={AppColor["Text/Primary Text"]}>
                          คะแนนที่ใช้
                        </Typography>
                        <Typography variant="Body/32" color="black" isCurrency>
                          {props.amount} คะแนน
                        </Typography>
                      </div>
                      <div style={{ marginTop: "8px" }} />
                      <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Typography variant="h4" color="black" isCurrency>
                          คิดเป็นเงิน {props.amount} บาท
                        </Typography>
                      </div>
                    </CardContent>
                  </Card>
                </div>
              )}
              <Button
                onClick={!isEmpty(props.checkHaveConsent) ? props.handleOpenConsentModal : props.handleClickSendRequest}
                style={{ marginBottom: 8 }}
                width={`100%`}
                variant="contained"
              >
                ยื่นคำขอ
              </Button>
            </>
          ) : (
            <Box display="flex" marginBottom={4}>
              {props.enableSaveDraftFeature && (
                <Button
                  disabled={props.isUploading}
                  onClick={props.handleClickSendDraftRequest}
                  marginRight={4}
                  width={`100%`}
                  variant="outlined"
                >
                  บันทึกร่าง
                </Button>
              )}
              <Button disabled={props.isUploading} onClick={props.onValidateRequest} width={`100%`} variant="contained">
                ถัดไป
                <CustomIconList name="ChevronRight" />
              </Button>
            </Box>
          )}
        </ContentBox>
      </PageContent>
    )}
    {props.step === 3 && (
      <PageContent
        title={props.titlePage}
        titleCentered
        onBack={props.handleClickBack}
        extendedTitleHeight={getPageStyle(props.currentUserIsHr, props.hrInstead)}
        overlapHeight={getPageStyle(props.currentUserIsHr, props.hrInstead)}
        type="linear"
      >
        <SuccessPage
          isRepresent={props.isRepresent}
          requester={props.requesterValues}
          requestName={props.title}
          type={props.type}
          amount={props.amount}
          referenceId={props.requestResponse.referenceId}
          createdDate={props.requestResponse.createdAt}
          icon={props.icon}
          iconColor={props.iconColor}
          chipTitleColor={props.chipTitleColor}
          chipBackgroundColor={props.chipBackgroundColor}
          name={props.name}
          currentUserIsHr={props.currentUserIsHr}
          hrInstead={props.hrInstead}
          disableConfirm={props.disableConfirm}
          disableConfirmRefresh={props.disableConfirmRefresh}
        />
      </PageContent>
    )}
  </>
)

const API = {
  GET_HISTORY_DETAIL: gql`
    query GET_HISTORY_DETAIL($id: String!) {
      claimRequestHistoryDetail(id: $id) {
        workflowSeq
        id
        type
        status
        config
        info
        employeeId
        employee
        createdAt
        updatedAt
        createdBy
        referenceId
        rejectReason
        remark
        transferDate
        canceled
        currentWorkflow
        approvedCount
        approvedAmount
        rejectedBy
        parentId
      }
    }
  `,
  VALIDATE_REQUEST: gql`
    mutation VALIDATE_REQUEST(
      $info: JSON!
      $employeeId: String!
      $requestName: String!
      $requestId: String
      $claimAction: String
    ) {
      validateClaim(
        inputs: {
          info: $info
          employeeId: $employeeId
          requestName: $requestName
          requestId: $requestId
          claimAction: $claimAction
        }
      ) {
        hasErrorMessage
      }
    }
  `,
  GET_CLAIM_REQUEST_CONFIGS: gql`
    query GET_CLAIM_REQUEST_CONFIGS {
      requestConfigs {
        requests
        master
      }
    }
  `,
  GET_MASTER_DATA: gql`
    query GET_MASTER_DATA {
      masterData
    }
  `,
  GET_EMPLOYEES: gql`
    query GET_EMPLOYEES {
      getEmployees {
        id
        employeeCode
        firstName
        lastName
        role
        grade
        employmentStartDate
        employmentType
        probationPeriod
        probationStatus
        functionalDesignation
      }
    }
  `,
  UPDATE_CLAIM_REQUEST: gql`
    mutation UPDATE_CLAIM_REQUEST(
      $type: String!
      $info: JSON!
      $employeeId: String!
      $requestId: String!
      $isHr: Boolean!
      $diseases: [String!]
    ) {
      editClaimRequest(
        input: {
          type: $type
          info: $info
          employeeId: $employeeId
          requestId: $requestId
          isHr: $isHr
          diseases: $diseases
        }
      ) {
        id
        referenceId
        createdAt
      }
    }
  `,
  UPDATE_DRAFT_CLAIM_REQUEST: gql`
    mutation UPDATE_DRAFT_CLAIM_REQUEST(
      $type: String!
      $info: JSON!
      $employeeId: String!
      $requestId: String!
      $isHr: Boolean!
      $diseases: [String!]
    ) {
      updateDraftClaimRequest(
        input: {
          type: $type
          info: $info
          employeeId: $employeeId
          requestId: $requestId
          isHr: $isHr
          diseases: $diseases
        }
      ) {
        id
        referenceId
        createdAt
      }
    }
  `,
  GET_CURRENT_USER: gql`
    query GET_CURRENT_USER {
      getCurrentUser {
        id
        employee {
          id
          employeeCode
          firstName
          lastName
          role
          grade
          employmentStartDate
          employmentType
          probationPeriod
          probationStatus
          functionalDesignation
          currentPoint
          department
          meta
        }
      }
    }
  `,
}

const enhancer = compose(
  withFormik({
    mapPropsToValues: () => ({}),
  }),
  withStores((stores: any) => ({
    currentUserInfo: stores.userStore.currentUser,
  })),
  withConfirmRefresh(),
  withConfirmGoBack(),

  withHooks((props: any, hooks: any) => {
    const { currentUserInfo, disableConfirm, disableConfirmRefresh, setValues, values, setFieldValue, dirty } = props
    const { useParams, useQuery, useCallback, useEffect, useState, useMutation, useMemo, useUrlParam } = hooks

    const queryParams = useUrlParam()
    const { tab, filters } = queryParams || {}

    const { id } = useParams()
    const { data, loading: detailLoading } = useQuery(API.GET_HISTORY_DETAIL, {
      variables: { id },
      fetchPolicy: "network-only",
    })
    const { data: configs } = useQuery(API.GET_CLAIM_REQUEST_CONFIGS)
    const { data: masterData } = useQuery(API.GET_MASTER_DATA, { fetchPolicy: "network-only" })
    const [validateRequest, { loading: validateClaimLoading }] = useMutation(API.VALIDATE_REQUEST)
    const { data: employeesRes } = useQuery(API.GET_EMPLOYEES)

    const [editClaimRequest, { loading: editClaimLoading }] = useMutation(API.UPDATE_CLAIM_REQUEST, {
      refetchQueries: [{ query: API.GET_CURRENT_USER }],
    })

    const [editDraftClaimRequest, { loading: editDraftClaimRequestLoading }] = useMutation(
      API.UPDATE_DRAFT_CLAIM_REQUEST,
      {
        refetchQueries: [{ query: API.GET_CURRENT_USER }],
      },
    )

    const [stepPage, setStepPage] = useState(1)
    const [isUploading, setIsUploading] = useState(false)
    const [requestResponse, setRequestResponse] = useState()
    const [selectedRequest, setSelectedRequest] = useState()
    const [imageLoading, setImageLoading] = useState(true)

    const [diseases, setDiseases] = useState([])
    const [newDiseases, setNewDiseases] = useState()

    const [isConsentModalOpen, setIsConsentModalOpen] = useState(false)

    useEffect(() => {
      if (configs) setSelectedRequest(configs.requestConfigs.requests[data?.claimRequestHistoryDetail.type])
    }, [configs, data?.claimRequestHistoryDetail.type])

    const [isPreview, setIsPreview] = useState(false)

    const handleClickBack = useCallback(() => {
      return mapClickBack(stepPage, disableConfirm, setIsPreview, setStepPage, tab, filters, setNewDiseases, diseases)
    }, [stepPage, disableConfirm, tab, filters, diseases])

    const initialInfoValues: InfoValuesConfig = useMemo(
      () => ({
        inputs: {},
        documents: {},
      }),
      [],
    )
    const [infoValues, setInfoValues] = useState(initialInfoValues)
    const [detailValues, setDetailValues] = useState({})
    const currentUser = useMemo(() => {
      return currentUserInfo.employee
    }, [currentUserInfo])

    const employeeOptions = useMemo(() => {
      return mapEmployeeOptions(employeesRes, data, currentUser)
    }, [employeesRes, data, currentUser])

    const employeeDataDic = useMemo(() => {
      return mapDataDic(employeesRes)
    }, [employeesRes])

    const currentUserIsHr = useMemo(() => employeeIsHr(currentUser.role), [currentUser])

    const currentActionFlows = useMemo(() => {
      const workflowSeq = get(data?.claimRequestHistoryDetail, "workflowSeq", [])
      const infoWorkflows = get(data?.claimRequestHistoryDetail.info, "workflows", [])
      const workflows = workflowSeq?.length > 0 ? workflowSeq : infoWorkflows
      console.log({ workflows })
      const current = data?.claimRequestHistoryDetail.currentWorkflow || 0
      const currentWorkflow = workflows?.find((flow: any) => flow.seq === current)
      return currentWorkflow
    }, [data])

    const employee = useMemo(() => data?.claimRequestHistoryDetail.employee, [data])

    const isRepresent = useMemo(() => {
      return data?.claimRequestHistoryDetail.employee.id !== currentUser.id
    }, [data, currentUser])

    const hasFile = useMemo(() => data?.claimRequestHistoryDetail.info.documents.filter((doc: any) => doc.file) > 0, [
      data,
    ])

    const isInstead = useMemo(
      () => data?.claimRequestHistoryDetail.employee.id !== data?.claimRequestHistoryDetail.createdBy.id,
      [data],
    )

    const defaultTransferDate = useMemo(() => {
      return setTranferDate(data)
    }, [data])
    const requestAmount = useCallback((history: any) => {
      return history?.info?.values?.inputs[history.info.amountFieldName] || 0
    }, [])

    const isInsurance = useMemo(() => checkInsurance(data), [data])

    const handleOpenConsentModal = useCallback(() => {
      setIsConsentModalOpen(true)
    }, [])

    const handleCloseConsentModal = useCallback(() => {
      setIsConsentModalOpen(false)
    }, [])

    const getValue = useCallback((type: INPUT_TYPE, formName: string, values: any) => {
      const value = values[formName]
      return mapValue(value, type)
    }, [])

    const getStringValue = useCallback((type: INPUT_TYPE, formName: string, values: any) => {
      const value = values[formName]
      return mapStringValue(value, formName, type, values)
    }, [])
    const setChildrenBirthDate = (values: any, setFieldValue: any) => {
      if (values.children_name) {
        setFieldValue("children_birthdate", values.children_name.birthDate)
      }
    }

    const onValidateError = useCallback((error: any) => {
      // @ts-ignore
      Modal.alert({
        className: "deleteConFirmModal",
        title: "",
        children: (
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Box justifyContent="center" padding={4}>
              <MuiIcon fontSize="large" color={AppColor["Warning"]} name="Warning"></MuiIcon>
            </Box>
            <Box justifyContent="flexStart" mb="16px">
              <List style={{ listStyleType: JSON.parse(error.message).length < 2 ? "none" : "inherit" }}>
                {JSON.parse(error.message).map((mes: any, index: number) => (
                  <li key={1150}>
                    <Typography variant="body1" color="Text/Black2">
                      {mes}
                    </Typography>
                  </li>
                ))}
              </List>
            </Box>
          </Box>
        ),
        okButtonLabel: "ตกลง",
        okButtonVariant: "contained",
        buttonWidth: "100%",
      })
    }, [])
    const openErrorModalAcademicYearMustUnique = (error: any) => {
      const isJsonError = JSON.parse(error.message)
      // @ts-ignore
      Modal.alert({
        title: "",
        children: (
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Box justifyContent="center" padding={2}>
              <MuiIcon fontSize="large" color={AppColor["Warning"]} name="Warning"></MuiIcon>
            </Box>
            <Box justifyContent="center" padding={4}>
              <Typography variant="h3" color={AppColor["Text/Primary Text"]}>
                เกิดข้อผิดพลาด
              </Typography>
            </Box>
            <Box
              style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}
              padding={1}
              mb="16px"
            >
              <Box>
                <Typography variant="Body/14" color={AppColor["Text/Primary Text"]}>
                  {isJsonError.message1}
                </Typography>
              </Box>
              <Box>
                <Typography variant="Body/14" color={AppColor["Other/Danger"]}>
                  "{isJsonError.message2}"
                </Typography>
              </Box>
              <Box>
                <Typography variant="Body/14" color={AppColor["Text/Primary Text"]}>
                  {isJsonError.message3}
                </Typography>
              </Box>
              <Box>
                <Typography variant="Body/14" color={AppColor["Text/Primary Text"]}>
                  {isJsonError.message4}
                </Typography>
              </Box>
              <Box>
                <Typography variant="Body/14" color={AppColor["Text/Primary Text"]}>
                  {isJsonError.message5}
                </Typography>
              </Box>
            </Box>
          </Box>
        ),
        okButtonLabel: "ตกลง",
        okButtonVariant: "contained",
        buttonWidth: "100%",
      })
    }

    const openModalSuccessDraft = useCallback(() => {
      // @ts-ignore
      Modal.open({
        className: "SuccesDraftModal",
        children: (
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
            <Icon width="40px" height="40px" name="Warning" color={AppColor["Error/Error Text"]} />
            <Box mt="16px">
              <Typography variant="h3" color={AppColor["Text/Primary Text"]}>
                บันทึกข้อมูลสำเร็จ
              </Typography>
            </Box>
            <Box mt="8px">
              <Typography variant="body1" color={AppColor["Text/Placeholder"]}>
                ข้อมูลการขอเบิกของคุณถูกบันทึกแล้ว <br />
                คุณสามารถแก้ไขข้อมูลต่อได้ที่เมนูประวัติการเบิก <br />
                ในแถบ "แบบร่าง" เมื่อพร้อม
              </Typography>
            </Box>
          </div>
        ),
        cancelButtonLabel: "ปิดหน้าต่างนี้",
        cancelButtonVariant: "outlined",
        okButtonLabel: "กลับหน้าหลัก",
        okButtonVariant: "contained",
        onOk: () => {
          // @ts-ignore
          Modal.close()
          disableConfirm()
          paths.landingPath().push()
        },
        onCancel: () => {
          // @ts-ignore
          Modal.close()
        },
      })
    }, [])

    const handleFilesChange = useCallback((values: any) => {
      setDocumentValues(values)
    }, [])

    const initialDocumentValues = useMemo(() => {
      let initialDocuments = {}
      data?.claimRequestHistoryDetail.info.documents.forEach((doc: any) => {
        initialDocuments = {
          ...initialDocuments,
          [doc.name]: getValueOrDefault(data?.claimRequestHistoryDetail.info.values.documents[doc.name], []),
        }
      })

      return initialDocuments
    }, [data?.claimRequestHistoryDetail.info.documents, data?.claimRequestHistoryDetail.info.values.documents])

    const [documentValues, setDocumentValues] = useState(initialDocumentValues)

    useEffect(() => {
      setDocumentValues(initialDocumentValues)
    }, [initialDocumentValues])

    const onValidateErrorAcademicYearMustUnique = useCallback((error: any) => {
      // @ts-ignore
      openErrorModalAcademicYearMustUnique(error)
    }, [])

    const handleValidateRequest = useCallback(
      async (info: any, requesterId: string, requestName: string, requestId?: string) => {
        return checkValidateReq(
          validateRequest,
          onValidateError,
          onValidateErrorAcademicYearMustUnique,
          info,
          requesterId,
          requestName,
          requestId,
        )
      },
      [onValidateError, onValidateErrorAcademicYearMustUnique, validateRequest],
    )

    const onValidateRequest = useCallback(async () => {
      if (isUploading) {
        return
      } // Prevent form submit when document is uploading.

      let initInputs = {}
      let initDocuments = {}
      const workflowSeq = data?.claimRequestHistoryDetail.workflowSeq
      const currentWorkflowSeq = data?.claimRequestHistoryDetail.currentWorkflow + 1
      const currentWorkflow = workflowSeq.find((workflow: any) => workflow.seq === currentWorkflowSeq)

      initInputs = mapInitInput(currentUserIsHr, currentWorkflow, data, getStringValue, values)

      data?.claimRequestHistoryDetail.info.documents.forEach((document: any) => {
        initDocuments = { ...initDocuments, [document.name]: getValueOrDefault(documentValues[document.name], []) }
      })

      const initValues: InfoValuesConfig = {
        inputs: initInputs,
        documents: initDocuments,
      }

      const info = {
        ...data?.claimRequestHistoryDetail.info,
        values: initValues,
        conditions: data?.claimRequestHistoryDetail.info.conditions,
        inputs: data?.claimRequestHistoryDetail.info.inputs,
      }

      const isValid = await handleValidateRequest(
        info,
        data?.claimRequestHistoryDetail.employeeId,
        data?.claimRequestHistoryDetail.type,
        currentUserIsHr && data?.claimRequestHistoryDetail.parentId ? data?.claimRequestHistoryDetail.id : undefined,
      )

      setDataForValidReq(isValid, initValues, setInfoValues, setIsPreview, setStepPage)
    }, [isUploading, data, currentUserIsHr, getStringValue, values, handleValidateRequest, documentValues])

    const handleClickSendRequest = useCallback(async () => {
      try {
        const info = {
          ...data?.claimRequestHistoryDetail.info,
          values: infoValues,
          // workflows: getWorkflow(data, configs, selectedRequest),
        }
        const res = await editClaimRequest({
          variables: {
            type: data?.claimRequestHistoryDetail.type,
            info,
            employeeId: data?.claimRequestHistoryDetail.employeeId,
            requestId: data?.claimRequestHistoryDetail.id,
            isHr: currentUserIsHr && data?.claimRequestHistoryDetail.parentId ? true : false,
            diseases,
          },
        })
        setRequestResponse(res.data.editClaimRequest)
        setStepPage(3)
      } catch (error: any) {
        showError(error, onValidateError)
      }
    }, [data, infoValues, editClaimRequest, currentUserIsHr, diseases, onValidateError])

    const handleClickSendDraftRequest = useCallback(async () => {
      try {
        if (isUploading) {
          return
        } // Prevent form submit when document is uploading.

        let initInputs = {}
        let initDocuments = {}
        const workflowSeq = data?.claimRequestHistoryDetail.workflowSeq
        const currentWorkflowSeq = data?.claimRequestHistoryDetail.currentWorkflow + 1
        const currentWorkflow = workflowSeq.find((workflow: any) => workflow.seq === currentWorkflowSeq)

        initInputs = mapInitInput(currentUserIsHr, currentWorkflow, data, getStringValue, values)

        data?.claimRequestHistoryDetail.info.documents.forEach((document: any) => {
          initDocuments = { ...initDocuments, [document.name]: getValueOrDefault(documentValues[document.name], []) }
        })

        const initValues: InfoValuesConfig = {
          inputs: initInputs,
          documents: initDocuments,
        }

        setInfoValues(initValues)

        const info = {
          ...data?.claimRequestHistoryDetail.info,
          values: initValues,
          // workflows: getWorkflow(data, configs, selectedRequest),
        }

        const res = await editDraftClaimRequest({
          variables: {
            type: data?.claimRequestHistoryDetail.type,
            info,
            employeeId: data?.claimRequestHistoryDetail.employeeId,
            requestId: data?.claimRequestHistoryDetail.id,
            isHr: currentUserIsHr,
            diseases,
          },
        })
        setRequestResponse(res?.data?.updateDraftClaimRequest)
        openModalSuccessDraft()
      } catch (error: any) {
        showError(error, onValidateError)
      }
    }, [
      isUploading,
      data,
      currentUserIsHr,
      getStringValue,
      values,
      editDraftClaimRequest,
      diseases,
      openModalSuccessDraft,
      documentValues,
      onValidateError,
    ])

    const isLoaded = useMemo(() => !detailLoading, [detailLoading])

    useEffect(() => {
      if (data?.claimRequestHistoryDetail) {
        const fetchedValues = data?.claimRequestHistoryDetail.info.values.inputs
        let initInputs = {}
        const inputs = getInputs(currentUserIsHr, currentActionFlows, data)
        inputs.forEach((input: any) => {
          initInputs = { ...initInputs, [input.name]: getValue(input.type, input.name, fetchedValues) }
        })
        setValues({ ...initInputs, requesterId: data?.claimRequestHistoryDetail.employee.id })
        setDetailValues({ ...fetchedValues })
      }
    }, [data, getValue, setValues, currentUserIsHr, currentActionFlows])

    useEffect(() => {
      const initInputs = mapInitInput(currentUserIsHr, currentActionFlows, data, getStringValue, values)

      const initValues: InfoValuesConfig = {
        inputs: initInputs,
        documents: { ...data?.claimRequestHistoryDetail.info.values.documents },
      }
      setInfoValues(initValues)
    }, [
      data?.claimRequestHistoryDetail.info.values.documents,
      getStringValue,
      currentActionFlows?.workflow.inputs,
      values,
      data?.claimRequestHistoryDetail.info.inputs,
      currentUserIsHr,
      currentActionFlows,
      data,
    ])

    const handleUploading = useCallback(() => {
      setIsUploading(true)
    }, [])

    const handleUploaded = useCallback(() => {
      setIsUploading(false)
    }, [])

    const isPassAwayRequest = useMemo(() => {
      const requestTitle = data?.claimRequestHistoryDetail.info.title
      const passAwayRequest = isPassaway(requestTitle)

      return passAwayRequest
    }, [data?.claimRequestHistoryDetail])

    const customizeInputs = useMemo(() => {
      return mapCustomizeInputs(currentUserIsHr, currentActionFlows, isPassAwayRequest, isInstead, data)
    }, [currentUserIsHr, currentActionFlows, isPassAwayRequest, isInstead, data])

    const currentYear = new Date().getFullYear() + 543

    const isHr = useMemo(() => checkIsHr(currentUserIsHr, isInstead), [currentUserIsHr, isInstead])

    const setDiseaseList = useCallback(
      (disease: string) => {
        const diseaseList = diseases
        diseaseList.push(disease)
        setDiseases(diseaseList)
      },
      [diseases],
    )
    const checkHaveConsent = useMemo(() => {
      let globalConfig
      let selectReuestConfig
      if (configs) globalConfig = configs?.requestConfigs?.confirmBeforeClaim
      if (selectedRequest) selectReuestConfig = selectedRequest.confirm_before_claim
      return selectReuestConfig === undefined ? globalConfig : selectReuestConfig
    }, [configs, selectedRequest])
    const closeModal = () => {
      // @ts-ignore
      Modal.close()
    }
    //test
    const modalConsentConfig = useCallback(() => {
      // @ts-ignore
      Modal.open({
        className: "ConsentModal",
        children: (
          <>
            <Header>
              <Typography className="title" variant="Body/16" color={AppColor["Primary/Primary Text"]}>
                {checkHaveConsent?.title}
              </Typography>
              <IconButton
                className="closeBtn"
                color={AppColor["Primary/Primary Text"]}
                variant="text"
                onClick={closeModal}
                style={{ padding: "0px" }}
              >
                <MuiIcon name="Close" fontSize="small" />
              </IconButton>
            </Header>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
              <Main>
                <Typography className="title" variant="Body/14" color={AppColor["Text/Placeholder"]}>
                  {checkHaveConsent?.description}
                </Typography>
              </Main>
            </div>
          </>
        ),
        cancelButtonLabel: "ปิดหน้าต่างนี้",
        cancelButtonVariant: "outlined",
        okButtonLabel: "ยืนยัน",
        okButtonVariant: "contained",
        onOk: async ({ close, values }: any) => {
          handleClickSendRequest()
          // @ts-ignore
          Modal.close()
        },
      })
    })
    useEffect(() => {
      setChildrenBirthDate(values, setFieldValue)
    }, [setFieldValue, values])
    return {
      enableSaveDraftFeature: true,
      modalConsentConfig,
      checkHaveConsent,
      claimId: data?.claimRequestHistoryDetail.referenceId,
      createdBy:
        data?.claimRequestHistoryDetail.createdBy.firstName + " " + data?.claimRequestHistoryDetail.createdBy.lastName,
      createdAt: data?.claimRequestHistoryDetail.createdAt,
      updatedAt: data?.claimRequestHistoryDetail.updatedAt,
      inputs: customizeInputs,
      documents: getValueOrDefault(data?.claimRequestHistoryDetail.info.documents, []),
      inputValues: getValueOrDefault(infoValues.inputs, {}),
      documentValues,
      title: getValueOrDefault(data?.claimRequestHistoryDetail.info.title, ""),
      subTitle: data?.claimRequestHistoryDetail.info.subTitle,
      icon: data?.claimRequestHistoryDetail.info.icon,
      iconColor: data?.claimRequestHistoryDetail.info.iconColor,
      chipTitleColor: data?.claimRequestHistoryDetail.info.chipTitleColor,
      chipBackgroundColor: data?.claimRequestHistoryDetail.info.chipBackgroundColor,
      type: data?.claimRequestHistoryDetail.info.type,
      name: data?.claimRequestHistoryDetail.type,
      approvedAmount: data?.claimRequestHistoryDetail.approvedAmount,
      approvedCount: data?.claimRequestHistoryDetail.approvedCount,
      claimStatus: data?.claimRequestHistoryDetail.status,
      currentUser,

      workflows: getValueOrDefault(data?.claimRequestHistoryDetail.workflowSeq, []),

      requesterValues: getRequesterName(employee),
      requester: employee,
      hasFile,
      requestAmount,
      claimRequest: data?.claimRequestHistoryDetail,
      transferDate: getValueOrDefault(data?.claimRequestHistoryDetail.transferDate, null),
      remark: getValueOrDefault(data?.claimRequestHistoryDetail.info.remark, []),
      transferFast: getValueOrDefault(data?.claimRequestHistoryDetail.info.transferFast, false),
      currentUserIsHr,
      isInstead,
      isInsurance,
      defaultTransferDate,

      isLoaded,

      claimFor: data?.claimRequestHistoryDetail.info.claimFor,
      affiliation: data?.claimRequestHistoryDetail.employee.title,
      email: getValueOrDefault(data?.claimRequestHistoryDetail.employee.officialMailId, "-"),
      employeeGroup: "-",
      phoneNumber: "-",

      master: masterData?.masterData,
      isPreview,
      setFieldValue,
      values,
      detailValues,

      conditions: getValueOrDefault(data?.claimRequestHistoryDetail.info.conditions, []),
      handleClickBack,

      isUploading,
      handleUploading,
      handleUploaded,

      employeeOptions,

      onValidateRequest,
      handleClickSendRequest,
      handleClickSendDraftRequest,

      requestResponse,
      isRepresent,
      amount: values[data?.claimRequestHistoryDetail.info.amountFieldName],

      step: stepPage,
      disableConfirmRefresh,
      disableConfirm,

      budgetDetail: getValueOrDefault(data?.claimRequestHistoryDetail.info.budget.detail, null),
      budgetValues: getValueOrDefault(data?.claimRequestHistoryDetail.info.detail, {}),
      yearlyBudget: getValueOrDefault(data?.claimRequestHistoryDetail.info.yearlyBudget, {}),
      year: currentYear,

      rejectReason: getValueOrDefault(data?.claimRequestHistoryDetail.rejectReason, null),
      rejectRemark: getValueOrDefault(data?.claimRequestHistoryDetail.remark, null),
      rejectedBy: getValueOrDefault(data?.claimRequestHistoryDetail.rejectedBy, null),
      parentId: getValueOrDefault(data?.claimRequestHistoryDetail.parentId, null),

      handleFilesChange,

      validateClaimLoading,
      editClaimLoading,
      isHr,

      imageLoading,
      setImageLoading,

      setDiseaseList,
      newDiseases,

      isConsentModalOpen,
      handleOpenConsentModal,
      handleCloseConsentModal,
    }
  }),
)

const isPreviewOrReadOnly = (isPreview: any, input: any) => {
  return isPreview
}

const getAuto = (isPreview: any, input: any) => {
  return isPreview ? undefined : input.auto
}

const checkIsSupport = (currentUserIsHr: any, title: any) => {
  return currentUserIsHr && title === "ค่าช่วยเหลือจัดการงานศพ (3 เท่า)"
}

const getPageStyle = (currentUserIsHr: any, hrInstead: any) => {
  return currentUserIsHr && hrInstead ? "370px" : "300px"
}

const checkIsHr = (currentUserIsHr: any, isInstead: any) => {
  return currentUserIsHr && isInstead
}

const getMarginTopPreview = (isPreview: any, hrInstead: any, currentUserIsHr: any, remark: any) => {
  if (isPreview) {
    if (hrInstead && currentUserIsHr && remark?.length === 0) {
      return "16px"
    } else if (remark?.length === 0) {
      return "16px"
    } else {
      return "0px"
    }
  } else {
    return "24px"
  }
}

const checkInsurance = (data: any) => {
  return (
    data?.claimRequestHistoryDetail.info.type === "ประกันกลุ่ม" ||
    data?.claimRequestHistoryDetail.info.type === "ประกันสังคม"
  )
}

const mapStringValue = (value: any, formName: string, type: INPUT_TYPE, values: any) => {
  if (value === undefined) {
    return undefined
  }
  switch (type) {
    case INPUT_TYPE.TIME_PICKER:
      return dayjs(value).format("HH:mm")
    case INPUT_TYPE.DATE:
      return dayjs(value).format("DD/MM/YYYY")
    case INPUT_TYPE.NUMBER:
    case INPUT_TYPE.CURRENCY:
    case INPUT_TYPE.SUM_AMOUNT:
      return toCurrency(value, { minimumFractionDigits: 0 })
    case INPUT_TYPE.CURRENCY_NUMBER:
      const newValue = typeof value === "string" ? Number(value.replaceAll(",", "")) : value
      return toCurrency(newValue, { minimumFractionDigits: 0 })
    case INPUT_TYPE.SELECT:
      return value === OptionValue.IS_OTHER ? values[formName + "_is_other"] : values[formName]
    case INPUT_TYPE.RELATION:
    case INPUT_TYPE.CHECKBOX:
      return value
    case INPUT_TYPE.DATE_TIME:
      return dayjs(value).format("DD/MM/YYYY, HH:mm")
    default:
      return value.toString()
  }
}

const mapValue = (value: any, type: INPUT_TYPE) => {
  if (!value) return undefined
  switch (type) {
    case INPUT_TYPE.DATE:
      return stringToDate(value)
    case INPUT_TYPE.DATE_TIME:
      return stringToDate(value)
    case INPUT_TYPE.CURRENCY:
      return typeof value === "string" ? Number(value.replaceAll(",", "")) : value
    default:
      return value
  }
}

const showError = (error: any, onValidateError: any) => {
  if (isJson(error.message)) {
    onValidateError(error)
  }
}

const checkValidateReq = async (
  validateRequest: any,
  onValidateError: any,
  onValidateErrorAcademicYearMustUnique: any,
  info: any,
  requesterId: any,
  requestName: any,
  requestId: any,
) => {
  try {
    const variables = { info, employeeId: requesterId, requestName, requestId, claimAction: EnumClaimAction.create }
    console.log(variables.claimAction)

    await validateRequest({
      variables,
    })
  } catch (error: any) {
    const isJsonError = isJson(error.message) ? JSON.parse(error.message) : error
    if (isJsonError["code"] === 567) {
      onValidateErrorAcademicYearMustUnique(error)
    } else if (isJson(error.message)) {
      onValidateError(error)
    }
    return false
  }
  return true
}

const setDataForValidReq = (isValid: any, initValues: any, setInfoValues: any, setIsPreview: any, setStepPage: any) => {
  if (isValid) {
    setInfoValues(initValues)
    setIsPreview(true)
    setStepPage(2)
  }
}

// const getWorkflow = (data: any, configs: any, selectedRequest: any) => {
//   return (
//     (isEmpty(data?.claimRequestHistoryDetail.workflowSeq)
//       ? configs?.requestConfigs?.workflows
//       : selectedRequest.workflows) || []
//   )
// }

const getInputs = (currentUserIsHr: any, currentActionFlows: any, data: any) => {
  return data?.claimRequestHistoryDetail.info.inputs
}

const mapInitInput = (currentUserIsHr: any, currentActionFlows: any, data: any, getStringValue: any, values: any) => {
  let initInputs = {}
  // if (currentUserIsHr && data?.claimRequestHistoryDetail.parentId) {
  //   currentActionFlows?.workflow.inputs.forEach((input: any) => {
  //     initInputs = { ...initInputs, [input.name]: getStringValue(input.type, input.name, values) }
  //   })
  // } else {
  data?.claimRequestHistoryDetail.info.inputs.forEach((input: any) => {
    initInputs = { ...initInputs, [input.name]: getStringValue(input.type, input.name, values) }
  })
  // }
  return initInputs
}

const isPassaway = (requestTitle: any) => {
  // only bam
  return (
    requestTitle === "ค่าช่วยเหลือจัดการงานศพ (3 เท่า)" ||
    requestTitle === "ค่าเจ้าภาพงานศพ" ||
    requestTitle === "ค่าอุปกรณ์เคารพศพ"
  )
}

const mapCustomizeInputs = (
  currentUserIsHr: any,
  currentActionFlows: any,
  isPassAwayRequest: any,
  isInstead: any,
  data: any,
) => {
  // if (currentUserIsHr && data?.claimRequestHistoryDetail.parentId) {
  //   const inputs = currentActionFlows?.workflow.inputs.map((input: any) => {
  //     return {
  //       ...input,
  //       options:
  //         isPassAwayRequest && !isInstead && input.options && Array.isArray(input.options)
  //           ? input.options.filter((input: any) => input !== "self")
  //           : input.options,
  //     }
  //   })
  //   return inputs
  // } else {
  const inputs = data?.claimRequestHistoryDetail.info.inputs.map((input: any) => {
    return {
      ...input,
      options:
        isPassAwayRequest && !isInstead && input.options && Array.isArray(input.options)
          ? input.options.filter((input: any) => input !== "self")
          : input.options,
    }
  })
  return inputs
  // }
}

const getRequesterName = (employee: any) => {
  return employee ? employee.firstName + " " + employee.lastName : undefined
}

const getValueOrDefault = (value: any, defaultValue: any) => {
  return value || defaultValue
}

const setTranferDate = (data: any) => {
  if (data?.claimRequestHistoryDetail.transferDate) return dayjs(data?.claimRequestHistoryDetail.transferDate)
  return dayjs()
}

const getMaster = (configs: any) => {
  if (configs) return configs.requestConfigs.master || {}
}

const mapDataDic = (employeesRes: any) => {
  if (employeesRes) {
    let dataDic: any = {}
    employeesRes.getEmployees.forEach((employee: any) => {
      dataDic[employee.id] = employee
    })
    return dataDic
  }
  return undefined
}

const mapEmployeeOptions = (employeesRes: any, data: any, currentUser: any) => {
  if (employeesRes) {
    const employees = employeesRes.getEmployees
    // only bam
    if (data?.claimRequestHistoryDetail.info.title === "ค่าช่วยเหลือจัดการงานศพ (3 เท่า)") {
      return employees
        .filter((emp: any) => emp.employeeCode !== currentUser.employeeCode)
        .map((employee: any) => ({
          label: `${employee.firstName} ${employee.lastName}`,
          value: employee.id,
        }))
    }
    return (
      employees
        // .filter((emp: any) => !employeeIsHr(emp.role) || emp.employeeCode === currentUser.employeeCode)
        .map((employee: any) => ({
          label: `${employee.firstName} ${employee.lastName}`,
          value: employee.id,
        }))
    )
  }
  return []
}

const mapClickBack = (
  stepPage: any,
  disableConfirm: any,
  setIsPreview: any,
  setStepPage: any,
  tab: any,
  filters: any,
  setNewDiseases: any,
  diseases: any,
) => {
  switch (stepPage) {
    case 1:
      paths.historyPath({ tab: tab, filters: filters }).push()
      break
    case 3:
      disableConfirm()
      paths.landingPath().push()
      break
    case 2:
      setIsPreview(false)
      setStepPage(stepPage - 1)
      setNewDiseases(diseases)
      break
    default:
      setStepPage(stepPage - 1)
      break
  }
}

const EditPage = enhancer(EditPageComponent)

export default EditPage
