import * as Sentry from "@sentry/react"
import "./wdyr"

import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"

import ReactDOM from "react-dom"
import "./mocks"

import { Provider } from "react-redux"

import { env } from "configs"
import { ApiProvider } from "api"

import DateFnsUtils from "@date-io/date-fns"
import { Helmet, HelmetProvider } from "react-helmet-async"

import { create } from "jss"
import { ThemeProvider } from "styled-components/macro"

import { ThemeProvider as MuiThemeProvider, StylesProvider, jssPreset } from "@material-ui/core/styles"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"

import createTheme from "theme"

import { THEMES } from "./constants"

import { BrowserRouter, ExcelGenerator, ImageLightbox, Modal, Notification } from "components"
import store from "stores"

import Routes from "routes/Routes"

import { MaterialDesignContent, SnackbarProvider } from "notistack"

import { MuiIcon } from "components/common/MuiIcon"

import { AuthenticationResult, EventMessage, EventType, PublicClientApplication } from "@azure/msal-browser"
import { MsalProvider } from "@azure/msal-react"
import { loginRequest, msalConfig } from "constants/authConfig"
import styled from "styled-components"
import { appTitle } from "constants/objects/title"

if (env.SENTRY_DSN && env.SENTRY_DSN !== "") {
  Sentry.init({
    environment: env.SENTRY_ENVIRONMENT || "dev",
    dsn: env.SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration()],
    // Tracing
    tracesSampleRate: env.SENTRY_TRACES_SAMPLE_RATE || 0.2, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      "localhost",
      /^https:\/\/dev-bam-e-claim-app\.moveplus\.dynu\.net/,
      /^https:\/flexi\.bam\.co\.th/,
    ],
  })
}

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  // "&.notistack-MuiContent-success": {
  //   backgroundColor: themeConfig.bam.APP_COLOR["Success"],
  // },
}))

export const msalInstance = new PublicClientApplication(msalConfig)

const accounts = msalInstance.getAllAccounts()
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0])
}

function loginRedirect() {
  try {
    msalInstance.loginRedirect(loginRequest)
  } catch (err) {
    console.log(err)
  }
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult
    const account = payload.account
    msalInstance.setActiveAccount(account)
  } else if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
    if (event.error?.name === "InteractionRequiredAuthError") {
      loginRedirect()
    } else {
      console.log("ACQUIRE_TOKEN_FAILURE")
    }
  } else if (event.eventType === EventType.LOGIN_FAILURE) {
    if (event.error?.name === "BrowserAuthError") {
      loginRedirect()
    } else {
      console.log("LOGIN FAILURE")
    }
  } else {
    // console.log("Callback finished")
  }
})

msalInstance
  .handleRedirectPromise()
  .then(() => {
    const account = msalInstance.getActiveAccount()
    if (account) console.log("SUCCESSFUL GET USER : ", account)
  })
  .catch((err: any) => {
    console.log(err)
  })

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point")!,
})

const theme = createTheme(THEMES.DEFAULT)

console.log("App started")

ReactDOM.render(
  <Provider store={store}>
    <MsalProvider instance={msalInstance}>
      <ApiProvider>
        <HelmetProvider>
          {/* <Helmet titleTemplate="E-Claim" defaultTitle="E-Claim" /> */}
          <Helmet titleTemplate={appTitle} defaultTitle={appTitle} />
          <StylesProvider jss={jss}>
            <SnackbarProvider
              maxSnack={3}
              autoHideDuration={3000}
              iconVariant={{
                success: <MuiIcon name="CheckBoxOutlined" style={{ marginRight: "10px" }} />,
              }}
              Components={{
                success: StyledMaterialDesignContent,
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <MuiThemeProvider theme={theme}>
                  <ThemeProvider theme={theme}>
                    <BrowserRouter>
                      <Routes />
                      <Modal storeName="appStore.mainModal" />
                      <Modal storeName="appStore.alertModal" />
                      <Modal storeName="appStore.confirmModal" />
                      <ImageLightbox storeName="appStore.imageLightbox" />
                      <Notification />
                      <ExcelGenerator storeName="appStore.excelGenerator" />
                    </BrowserRouter>
                  </ThemeProvider>
                </MuiThemeProvider>
              </MuiPickersUtilsProvider>
            </SnackbarProvider>
          </StylesProvider>
        </HelmetProvider>
      </ApiProvider>
    </MsalProvider>
  </Provider>,
  document.getElementById("root"),
)
