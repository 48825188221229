import { ReactComponent as Point } from "assets/icon/point.svg"
import { Box, Grid, MuiIcon, Typography } from "components"
import { ClaimCard } from "components/advance/ClaimCard"
import { compose, withFormik, withHooks, withStores } from "enhancers"
import { env } from "configs"
import { PageContent } from "layouts"
import paths from "routes/paths"
import styled from "styled-components"
import { AppColor } from "theme/app-color"
import { gql } from "utils/helper"
const Content = styled(Box)`
  padding: 16px;
  background-color: ${AppColor["Text/Background"]};
`

const ContainerFlex = styled.div<{ js: string; row?: boolean; w100?: boolean; mt?: string; al?: string }>`
  display: flex;
  justify-content: ${(props) => props.js};
  flex-direction: ${(props) => (props.row ? "row" : "column")};
  width: ${(props) => (props.w100 ? "100%" : "none")};
  margin-top: ${(props) => (props.mt ? props.mt : "0px")};
  align-items: ${(props) => props.al};
`

const LandingPageComponent = (props: any) => (
  <>
    <PageContent
      title="สวัสดิการทั้งหมด"
      type="gray"
      onBack={props.handleClose}
      pageBackgroundColor={AppColor["Text/Background"]}
    >
      {env.USER_CAN_SEE_REMAINING_BUDGET && (
        <div
          style={{
            padding: "8px 16px",
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: AppColor["Primary/Primary Text"],
            alignItems: "center",
          }}
        >
          <Typography variant="body1" color={AppColor["White / White"]}>
            คะแนนที่มีอยู่
          </Typography>
          <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
            <Point></Point>
            <Typography variant="body1" color={AppColor["White / White"]} isCurrency>
              {props.remainingPoint} คะแนน
            </Typography>
          </div>
        </div>
      )}
      <Content>
        <Grid container>
          {props.layouts.map((layout: any, index: number) => (
            <>
              <ContainerFlex js="start" w100>
                <ContainerFlex js="flex-start" al="center" row w100 mt={index === 0 ? "8px" : "32px"}>
                  <Box mr="12px" display="flex" alignItems="center" justifyContent="center">
                    <MuiIcon name={layout.icon} fontSize="small" color={layout.iconColor} />
                  </Box>
                  <Typography variant="h3" color={layout.titleColor}>
                    {layout.title}
                  </Typography>
                </ContainerFlex>
                <Box mt="4px" mb="16px">
                  <Typography variant="subtitle2" color={AppColor["Text/Secondary Text"]}>
                    {layout.description}
                  </Typography>
                </Box>
              </ContainerFlex>
              {layout.sectionList.map((section: any) => (
                <>
                  <Grid container spacing={3}>
                    {section.requestTypeList.map((requestName: string, index: number) => {
                      const request = props.claimRequests[requestName]
                      const budgetValues = props.budgets[requestName]?.list || {}
                      if (request)
                        return (
                          <ClaimCard
                            key={requestName}
                            onClick={() => props.handleClickRequest(requestName)}
                            type={request.type}
                            name={requestName}
                            approvedRequest={request.approvedCount}
                            totalAmount={request.approvedAmount}
                            title={request.title}
                            icon={request.icon}
                            iconColor={request.iconColor}
                            chipTitleColor={request.chipTitleColor}
                            chipBackgroundColor={request.chipBackgroundColor}
                            budget={request.budget ? request.budget.list : null}
                            budgetValues={budgetValues}
                            yearlyBudget={request.type === "ค่ารักษาพยาบาล" ? props.yearlyBudget : {}}
                          />
                        )

                      return <></>
                    })}
                  </Grid>
                </>
              ))}
            </>
          ))}
        </Grid>
      </Content>
    </PageContent>
  </>
)

export const API = {
  GET_CLAIM_REQUEST_CONFIGS: gql`
    query GET_CLAIM_REQUEST_CONFIGS {
      requestConfigs {
        layouts
        requests
        usage
        master
        workflows
      }
    }
  `,
  GET_EMPLOYEE_REQUEST_BUDGETS: gql`
    query GET_EMPLOYEE_REQUEST_BUDGETS($employeeId: String!, $childrenName: String!) {
      employeeRequestBudgets(employeeId: $employeeId, childrenName: $childrenName) {
        usage
      }
    }
  `,
  GET_EMPLOYEE_YEARLY_BUDGETS: gql`
    query GET_EMPLOYEE_YEARLY_BUDGETS($filters: JSON) {
      getEmployeeYearlyBudgets(input: { filters: $filters }) {
        id
        employeeCode
        firstName
        lastName
        budget
        dentalBudget
        year
        companyBudget
        companyDentalBudget
      }
    }
  `,
}

const enhancer = compose(
  withFormik({}),
  withStores((stores: any) => ({
    currentUserInfo: stores.userStore.currentUser,
  })),
  withHooks((props: any, hooks: any) => {
    const { currentUserInfo } = props
    const { useQuery, useCallback, useMemo, useState, useLazyQuery, useEffect } = hooks
    const [allBudget, setAllBudgets] = useState({})
    const [yearlyBudget, setYearlyBudget] = useState({})

    const [refetchYearlyBudget] = useLazyQuery(API.GET_EMPLOYEE_YEARLY_BUDGETS, {
      onCompleted: (dataBudget: any) => {
        const yearlyBudgetData = dataBudget.getEmployeeYearlyBudgets[0]
        setYearlyBudget(yearlyBudgetData)
      },
      fetchPolicy: "network-only",
    })

    const [refetchBudget] = useLazyQuery(API.GET_EMPLOYEE_REQUEST_BUDGETS, {
      onCompleted: (data: any) => {
        const current = data.employeeRequestBudgets.usage
        setAllBudgets(current)
      },
      fetchPolicy: "network-only",
    })

    const { data } = useQuery(API.GET_CLAIM_REQUEST_CONFIGS, {
      fetchPolicy: "cache-and-network",
    })

    useEffect(() => {
      if (currentUserInfo) {
        refetchBudget({
          variables: { employeeId: currentUserInfo?.employee.id, childrenName: "" },
        })
      }
    }, [currentUserInfo, refetchBudget])

    useEffect(() => {
      if (currentUserInfo && data?.requestConfigs) {
        const variables = {
          filters: {
            code: currentUserInfo.employee.employeeCode,
            fullName: "",
            year: new Date(data?.requestConfigs.createdAt).getFullYear(),
            isApp: true,
          },
        }

        refetchYearlyBudget({ variables: variables })
      }
    }, [currentUserInfo, data?.requestConfigs, refetchYearlyBudget])

    const config = useMemo(() => data?.requestConfigs, [data])

    const handleClickRequest = useCallback((name: string) => {
      paths.claimDetailPath({ name }).push()
    }, [])

    const handleClose = useCallback(() => {
      paths.landingPath().push()
    }, [])

    const remainingPoint = useMemo(() => currentUserInfo?.employee.currentPoint, [currentUserInfo])

    return {
      layouts: config?.layouts.allRequests || [],
      claimRequests: config?.requests || {},
      handleClickRequest,
      handleClose,
      remainingPoint,
      budgets: allBudget,
      yearlyBudget: yearlyBudget || {},
    }
  }),
)

export default enhancer(LandingPageComponent)
