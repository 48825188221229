import { ApolloError } from "@apollo/client"
import { Card, CardContent } from "@material-ui/core"
import { Box, Button, Grid, Icon, MuiIcon, Typography } from "components"
import { ClaimCard } from "components/advance/ClaimCard"
import { compose, withHooks } from "enhancers"
import { env } from "configs"
import { PageContent } from "layouts"
import { Link } from "react-router-dom"
import userStore from "stores/userStore"
import styled from "styled-components"
import { AppColor } from "theme/app-color"
import { employeeIsHr, gql, paths } from "utils/helper"
import LogoutModal from "./logout-modal"
import { appTitle } from "constants/objects/title"

const SectionHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
`

const ListContainer = styled(Box)`
  padding: 0px 16px 16px 16px;
`

const CustomIconList = styled(MuiIcon)`
  font-size: 20px;
  margin-left: 8px;
`

const CurrentUser = styled.div`
  width: 100%;
  margin-bottom: 24px;
`

const SectionTitleAction = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 16px;
`

const LandingComponent = (props: any) => (
  <>
    <PageContent
      // title="E-claim"
      title={appTitle}
      titleCentered
      // onListClickTo="/claim-requests?tab=WAITING"
      extendedTitleHeight="220px"
      overlapHeight="200px"
      showDashBoardIcon={false}
    >
      <ListContainer>
        <CurrentUser>
          <Box style={{ position: "relative" }}>
            <Box display="flex" justifyContent="" flexDirection="column">
              <Typography variant="h1" color="White / White">
                สวัสดี
              </Typography>
              <Typography variant="h1" color="White / White">
                {props.currentUserInfo?.employee.firstName + " " + props.currentUserInfo?.employee.lastName}
              </Typography>
              {env.USER_CAN_SEE_REMAINING_BUDGET && (
                <Card style={{ marginTop: "12px", boxShadow: " 0 0 1px" }}>
                  <CardContent
                    style={{ display: "flex", flexDirection: "column", padding: "16px", alignItems: "center" }}
                  >
                    <Typography variant="h4" color={AppColor["Primary/Primary Text"]}>
                      คะแนนคงเหลือปี 2566
                    </Typography>
                    <div style={{ marginBottom: "16px", marginTop: "16px", display: "flex", gap: "8px" }}>
                      <Icon name="point" />
                      <Typography variant="Body/32" color={AppColor["Text/Primary Text"]} isCurrency>
                        {props.currentPoint}
                      </Typography>
                      <Typography variant="body1" color={AppColor["Text/Primary Text"]}>
                        คะแนน
                      </Typography>
                    </div>

                    <Typography variant="h4" color={AppColor["Text/Line"]} isCurrency>
                      คิดเป็นเงิน {props.currentPoint} บาท
                    </Typography>
                  </CardContent>
                </Card>
              )}
            </Box>
            {props.showOldButton && (
              <Box style={{ position: "absolute", right: 0, top: 0 }}>
                <Link
                  to="/history?tab=WAITING"
                  style={{ display: "flex", alignItems: "center", color: AppColor["White / White"] }}
                >
                  <MuiIcon name="Restore" style={{ fontSize: "14px", marginRight: "2px" }} />
                  <Typography variant="subtitle1" color="White / White">
                    ประวัติการเบิก
                  </Typography>
                </Link>
              </Box>
            )}
            <SectionTitleAction>
              <Box>
                <Link
                  to="/history?tab=WAITING"
                  style={{ display: "flex", alignItems: "center", color: AppColor["White / White"] }}
                >
                  <MuiIcon name="Restore" style={{ fontSize: "14px", marginRight: "2px" }} />
                  <Typography variant="subtitle1" color="White / White">
                    ประวัติการเบิก
                  </Typography>
                </Link>
              </Box>
              {props.isShowIcon && (
                <Box>
                  <Link
                    to="/claim-requests?tab=WAITING"
                    style={{ display: "flex", alignItems: "center", color: AppColor["White / White"] }}
                  >
                    <MuiIcon name="DescriptionOutlined" style={{ fontSize: "14px", marginRight: "2px" }} />
                    <Typography variant="subtitle1" color="White / White">
                      {`รายการขอเบิก (${props.listAmount})`}
                    </Typography>
                  </Link>
                </Box>
              )}
            </SectionTitleAction>
          </Box>
        </CurrentUser>
        <SectionHeader style={{ display: "flex" }}>
          <Box mr="8px">
            <Typography variant="h3" color="White / White">
              สวัสดิการพนักงาน
            </Typography>
          </Box>
          <Box mt="2px">
            <Typography variant="subtitle1" color="White / White">
              ( {props.length} รายการ )
            </Typography>
          </Box>
        </SectionHeader>
        <Grid container>
          <Grid container mt={4}>
            {props.claimRequestConfigs.map((data: any, ind: number) => {
              return (
                <ClaimCard
                  key={data.name}
                  onClick={() => props.handleClickRequest(data)}
                  type={data.type}
                  name={data.name}
                  approvedRequest={data.approvedCount}
                  totalAmount={data.approvedAmount}
                  title={data.title}
                  icon={data.icon}
                  iconColor={data.iconColor}
                  chipTitleColor={data.chipTitleColor}
                  chipBackgroundColor={data.chipBackgroundColor}
                  fullSize
                />
              )
            })}
          </Grid>
        </Grid>
        {props.showButtonSeeAll && (
          <Link to="/claim-list" style={{ textDecoration: "none" }}>
            <Button width="100%" variant="outlined">
              สวัสดิการทั้งหมด
              {<CustomIconList name="ChevronRight" />}
            </Button>
          </Link>
        )}

        <Box py={6} onClick={props.handleOpenLogoutModal}>
          <Typography
            variant="body1"
            color="Primary/Primary Text"
            align="center"
            style={{ textDecoration: "underline", cursor: "pointer" }}
          >
            ออกจากระบบ
          </Typography>
        </Box>
      </ListContainer>
      <LogoutModal isOpen={props.isLogoutModalOpen} onClose={props.handleCloseLogoutModal} />
    </PageContent>
  </>
)

export const API = {
  GET_CLAIM_REQUEST_CONFIGS: gql`
    query GET_CLAIM_REQUEST_CONFIGS {
      requestConfigs {
        layouts
        requests
        usage
      }
    }
  `,
  GET_CURRENT_USER: gql`
    query GET_CURRENT_USER {
      getCurrentUser {
        id
        employee {
          id
          employeeCode
          firstName
          lastName
          role
          grade
          employmentStartDate
          employmentType
          probationPeriod
          probationStatus
          functionalDesignation
          currentPoint
          department
          meta
        }
      }
    }
  `,
  GET_CLAIM_REQUEST_LIST: gql`
    query GET_CLAIM_REQUEST_LIST {
      shouldShowClaimList {
        shouldDisplayRequestList
        waitingClaimRequestCount
      }
    }
  `,
}

const enhancer = compose(
  withHooks((props: any, hooks: any) => {
    const { useQuery, useEffect, useCallback, useMemo, useState, useLazyQuery } = hooks
    const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false)

    const { data: currentUserQry, refetch } = useQuery(API.GET_CURRENT_USER, {
      fetchPolicy: "network-only",
      onCompleted: (data: any) => {
        userStore.setCurrentUser(data.getCurrentUser)
      },
      onError: (error: ApolloError) => {
        userStore.setCurrentUser(null)
      },
    })
    const { data } = useQuery(API.GET_CLAIM_REQUEST_CONFIGS, {
      fetchPolicy: "cache-and-network",
    })

    const { data: fetchShouldShowClaimList } = useQuery(API.GET_CLAIM_REQUEST_LIST, { fetchPolicy: "network-only" })

    const currentUser = useMemo(() => currentUserQry?.getCurrentUser, [currentUserQry])
    const userIsHr = useMemo(() => employeeIsHr(currentUser?.employee.role), [currentUser])
    const currentPoint = useMemo(() => currentUser?.employee.currentPoint || 0, [currentUser])
    const config = useMemo(() => data?.requestConfigs, [data])
    const year = useMemo(() => new Date().getFullYear() + 543, [])
    const showButtonSeeAll = useMemo(() => config?.layouts.allRequests.length > 0, [config])

    const handleClickRequest = useCallback((value: any) => {
      paths.claimDetailPath({ name: value.name }).push()
    }, [])

    const claimRequestConfigs = useMemo(() => {
      if (config)
        return config.layouts.topRequests.map((name: string) => {
          const request = { ...config.requests[name], name }
          const usage = config.usage[name]
          return {
            ...request,
            ...usage,
          }
        })
      return []
    }, [config])

    const totalClaimRequest = useMemo(() => {
      if (!config?.layouts?.allRequests) return 0

      return config.layouts.allRequests
        .map((allRequest: any) => {
          return allRequest.sectionList.reduce((sum: number, section: any) => sum + section.requestTypeList.length, 0)
        })
        .reduce((sum: number, total: number) => sum + total, 0)
    }, [config])

    const handleOpenLogoutModal = useCallback(() => {
      setIsLogoutModalOpen(true)
    }, [])

    const handleCloseLogoutModal = useCallback(() => {
      setIsLogoutModalOpen(false)
    }, [])

    useEffect(() => {
      if (env.USER_CAN_SEE_REMAINING_BUDGET) {
        const performAction = () => {
          refetch()
        }
        const intervalId = setInterval(performAction, 15000)
        return () => clearInterval(intervalId)
      }
    }, [refetch])

    return {
      claimRequestConfigs,
      length: totalClaimRequest,
      handleClickRequest,
      currentUserInfo: currentUser,
      userIsHr,
      currentPoint,
      year,
      showButtonSeeAll,
      handleOpenLogoutModal,
      handleCloseLogoutModal,
      isLogoutModalOpen,
      listAmount: fetchShouldShowClaimList?.shouldShowClaimList?.waitingClaimRequestCount,
      isShowIcon: fetchShouldShowClaimList?.shouldShowClaimList?.shouldDisplayRequestList,
      showOldButton: false,
    }
  }),
)

export default enhancer(LandingComponent)
